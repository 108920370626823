<template>
  <!-- 出库管理 -->
  <div class="stockManagement">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :form-inline="formInline" :form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button size="small" type="primary" @click="addRow">
          添加
        </el-button>
      </div>
      <Table :item-data="itemData" :list-data="listData" :loading="loading" />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
  </div>
</template>

<script>
import FormSearch from '../../components/FormSearch'
import Table from '../../components/Table'
import Pagination from '../../components/Pagination2'
import { timestampToTime } from '../../utils/util'
import { warehouseList, inventoryOutPage } from '../../api/warehousingSystem'
export default {
  components: { FormSearch, Table, Pagination },
  data() {
    return {
      cmpNameArr: [],
      editFormData: {},
      itemData: [
        { label: '出库编号', prop: 'outNo', width: 180, path: '/warehousingSystem/warehouseDetails', pathParameter: 'id' },
        { label: '仓库名称', prop: 'warehouseName', width: 180 },
        { label: '货主名称', prop: 'custName', width: 180 },
        // { label: '品名', prop: 'goodsName', width: 180 },
        { label: '运单号', prop: 'waybillNo', width: 100 },
        { label: '司机', prop: 'driverName', width: 100 },
        { label: '司机电话', prop: 'driverPhone', width: 120 },
        // { label: '出库数量', prop: 'weight', width: 180 },
        { label: '出库时间', prop: 'outDate', width: 180 }
      ],
      formItemArr: [
        { type: 'input', label: '出库编码', value: 'outNo' },
        // { type: 'input', label: '库存编码', value: 'inventoryNo' },
        { type: 'input', label: '货主名称', value: 'custName' },
        { type: 'input', label: '仓库名称', value: 'warehouseName' }
      ],
      loading: false,
      total: 0,
      listData: [],
      formInline: {
        pageSize: 10,
        inventoryNo: '',
        pageNum: 1
      }
    }
  },
  mounted() {
    if (this.$route.query.inventoryNo) {
      this.formInline.inventoryNo = this.$route.query.inventoryNo
    }
    warehouseList('', res => {
      this.formItemArr.forEach((item) => {
        if (item.value === 'warehouseName') item.child = [...res.data]
      })
    })
  },
  methods: {
    addRow() {
      this.$router.push({ path: '/warehousingSystem/warehouseStorage' })
    },
    // 获取列表数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      inventoryOutPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.listData.forEach(item => {
          item.outDate = timestampToTime(item.outDate)
        })
        this.total = res.data.total
      })
    }
  }
}
</script>

<style scoped>
.cmpNameSearch {
  width: 100%;
}
.marginTop {
  padding-top: 18px;
}
</style>
